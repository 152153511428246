<div class="panel-padding">
    <h2>
        {{ 'order' | translate }}:
        <strong>{{ currentOrder.id }}</strong>
    </h2>
    <p>
        <strong> {{ 'order.date' | translate }}: {{ currentOrder.createdOn | date }} </strong>
    </p>
    <div class="row">
        <div class="print-only col-sm-6">
            <div class="float-left">
                <h3>{{ 'order.customer' | translate }}</h3>
                <p>{{ currentOrder.customer.name }} {{ currentOrder.customer.surname }}</p>
                <p>{{ currentOrder.customer.address.street }}</p>
                <p>{{ currentOrder.customer.address.city }}</p>
                <p>{{ currentOrder.customer.address.country }}</p>
                <p></p>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="float-right">
                <h3>{{ 'order.samplingByVet' | translate }}</h3>
                <p style="font-size: x-small">{{ 'order.samplingByVetNote' | translate }}</p>
                <div class="row">
                    <div class="col-sm-6">{{ 'order.vetName' | translate }}</div>
                    <div class="col-sm-6">
                        <p style="border-bottom: 1px dotted black">&nbsp;</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">{{ 'sample.samplingVetNo' | translate }}</div>
                    <div class="col-sm-6">
                        <p style="border-bottom: 1px dotted black">&nbsp;</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">{{ 'order.vetAddress' | translate }}</div>
                    <div class="col-sm-6">
                        <p style="border-bottom: 1px dotted black">&nbsp;</p>
                    </div>
                </div>
                <div class="row" style="margin-top: 30px">
                    <div class="col-sm-6">{{ 'order.vetStamp' | translate }}</div>
                    <div class="col-sm-6"><p style="border-bottom: 1px dotted black">&nbsp;</p></div>
                </div>
                <p></p>
            </div>
        </div>
    </div>

    <div class="card card-outline-primary">
        <div class="card-header bg-primary text-white">
            {{ 'order.items' | translate }}
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ 'order.sample' | translate }}</th>
                            <th>{{ 'sample.type' | translate }}</th>
                            <th>{{ 'order.analysis' | translate }}</th>
                            <th>{{ 'sample.samplingDate' | translate }}</th>
                            <th>{{ 'sample.labCode' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of currentOrder.items | sort : 'asc' : 'id'; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.sampleLabel }}</td>
                            <td>{{ item.sample.extractionMethod.name }}</td>
                            <td>{{ item.analysisLabel }}</td>
                            <td>{{ item.sample.samplingDate | date }}</td>
                            <td>{{ item.sample.labCode }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row text-left mt-4">
        <div class="col-sm-6">{{ 'order.note' | translate }}</div>
        <div class="col-sm-6">
            <strong>{{ currentOrder.note }}</strong>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-sm-6">{{ 'order.paymentMethod' | translate }}</div>
        <div class="col-sm-6">
            <strong *ngIf="currentOrder.paymentMethod">{{
                'order.payment.' + currentOrder.paymentMethod | translate
            }}</strong>
            <div *ngIf="currentOrder.vouchers && currentOrder.vouchers.length > 0" class="py-2">
                <span class="border-1 mr-2 p-1" *ngFor="let v of currentOrder.vouchers">{{v.id}}</span>
            </div>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-sm-6">{{ 'order.samplingKitRequested' | translate }}</div>
        <div class="col-sm-6">
            <strong>
                <span *ngIf="currentOrder.samplingKitRequested">
                    {{ 'common.yes' | translate }} -
                    {{
                        currentOrder.samplingKitType && extractionMethodMap[currentOrder.samplingKitType]
                            ? extractionMethodMap[currentOrder.samplingKitType].label
                            : currentOrder.samplingKitType
                    }}
                </span>
                <span *ngIf="!currentOrder.samplingKitRequested">{{ 'common.no' | translate }}</span>
            </strong>
        </div>
    </div>
</div>
