import { Component, Input } from '@angular/core'
import { OrderStatus } from '@resultsgate-ui/api'

@Component({
    selector: 'oc-order-status',
    templateUrl: './order-status.component.html',
    styleUrls: ['./order-status.component.scss'],
})
export class OrderStatusComponent {
    @Input()
    status: OrderStatus
}
