<div class="card card-accent-{{ colors[index % 4] }} item-idx-{{ index }}">
    <div class="shadow-toggler" #shadowToggle></div>
    <div class="card-header sticky" #cardHeader>
        <strong
            >{{ 'order.sample' | translate }} {{ singleMode ? '' : index + 1
            }}<span *ngIf="af.name.value">: {{ af.name.value }} </span></strong
        >
        <div class="card-header-actions">
            <a *ngIf="index !== 0 && !singleMode" (click)="removeSample(index)" class="btn btn-sm btn-warning mr-1">{{
                'common.remove' | translate
            }}</a>
            <button
                *ngIf="adminMode && !singleMode"
                pTooltip="{{ 'order.duplicateItem' | translate }}"
                type="button"
                class="btn btn-sm btn-primary mr-2"
                (click)="onDuplicate(index)"
            >
                <i class="fas fa-copy"></i>
            </button>
            <button type="button" class="btn btn-sm btn-primary" (click)="collapsed = !collapsed">
                <i class="fas" [ngClass]="{ 'fa-expand-alt': collapsed, 'fa-compress-alt': !collapsed }"></i>
            </button>
        </div>
    </div>
    <div class="card-body" [formGroup]="sampleFormGroup" [collapse]="collapsed" [isAnimated]="true">
        <div class="" *ngIf="!internalOrder">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label"></label>

                <div class="col-sm-9">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            value="NEW"
                            id="{{ index }}-new"
                            formControlName="mode"
                            (change)="toggleMode()"
                        />
                        <label class="form-check-label" for="{{ index }}-new">
                            {{ 'order.defineNewSample' | translate }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            value="EXISTING"
                            id="{{ index }}-existing"
                            formControlName="mode"
                            (change)="toggleMode()"
                        />
                        <label class="form-check-label" for="{{ index }}-existing">
                            {{ 'order.chooseExistingSample' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row" id="existingsample{{ index }}" *ngIf="f.mode.value === 'EXISTING'">
                <label class="col-sm-3 col-form-label">{{ 'order.existingSample' | translate }}</label>
                <div class="col-sm-9">
                    <ng-select
                        [items]="customerSamples | async"
                        bindValue="id"
                        bindLabel="label"
                        formControlName="existingSampleId"
                        (change)="existingSampleSelected($event)"
                    >
                    </ng-select>
                </div>
            </div>
        </div>

        <!--
    TODO any sample
    <div class="row" *ngIf="currentOrder.internalOrder">
        <div class="form-group row">
            <label for="samplequery" class="col-sm-3 col-form-label"> {{ 'order.existingSample' | translate }}</label>
            <div class="col-sm-9">
                <div
                    angucomplete
                    pause="500"
                    selectedobject="selSample"
                    resultfunction="sampleFromSearchSelected(res.originalObject, $index)"
                    searchfunction="searchSamples(qstring, sfn, efn)"
                    titlefield="labCode"
                    minlength="2"
                    inputclass="form-control"
                ></div>
            </div>
        </div>
    </div> -->
        <div class="form-row animal-info" formGroupName="animal">
            <div class="col-md-4">
                <label class="col-form-label">{{ 'sample.animal' | translate }}</label>
                <ng-select
                    formControlName="rootCategoryId"
                    [clearable]="false"
                    [bindValue]="'id'"
                    [bindLabel]="'label'"
                    [searchable]="false"
                    (change)="onRootCategorySelected($event.id)"
                    [items]="rootCategoriesSelectItems"
                >
                </ng-select>
            </div>
            <div class="col-md-4">
                <label *ngIf="!isBird" class="col-form-label">{{ 'sample.breed' | translate }}</label>
                <label *ngIf="isBird" class="col-form-label">{{ 'sample.species' | translate }}</label>
                <div class="d-flex align-items-center">
                    <ng-select
                        style="flex: 1"
                        formControlName="categoryId"
                        [bindValue]="'id'"
                        [bindLabel]="'label'"
                        [virtualScroll]="true"
                        [items]="this.cache.categoriesMap[af.rootCategoryId.value]"
                    >
                        <ng-template ng-label-tmp let-item="item">
                            <ng-container *ngIf="!isBird">{{ item.label }}</ng-container>
                            <ng-container *ngIf="isBird">
                                <oc-bird-label [bird]="item.label"></oc-bird-label>
                            </ng-container>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <ng-container *ngIf="!isBird">{{ item.label }}</ng-container>
                            <ng-container *ngIf="isBird">
                                <oc-bird-label [bird]="item.label"></oc-bird-label>
                            </ng-container>
                        </ng-template>
                    </ng-select>
                    <i
                        *ngIf="isBird"
                        class="fas fa-question-circle mx-2"
                        pTooltip="{{ 'sample.category.help' | translate }}"
                        [escape]="false"
                    ></i>
                </div>

                <ui-show-errors [control]="af.categoryId"></ui-show-errors>
            </div>
            <div class="col-md-4" *ngIf="af.categoryId.value === unknownCategory">
                <label class="col-form-label">{{ 'sample.category.info' | translate }}</label>
                <input formControlName="categoryInfo" type="text" class="form-control" />
                <ui-show-errors [control]="af.categoryInfo"></ui-show-errors>
            </div>
        </div>
        <!--DOGS Identifiers-->
        <div class="form-row" formGroupName="animal" *ngIf="!isBird">
            <div class="col-md-12">
                <label class="col-form-label">{{ 'sample.animal.' + af.rootCategoryId.value | translate }}</label>
                <input
                    [attr.focustrap]="true"
                    formControlName="name"
                    [placeholder]="'sample.animal.nameInfo' | translate"
                    type="text"
                    class="form-control"
                />
                <ui-show-errors [control]="af.name"></ui-show-errors>
            </div>
            <div class="col-md-4" formGroupName="identifiers">
                <label class="col-form-label">{{ 'sample.rfid' | translate }}</label>
                <input formControlName="rfid" type="text" class="form-control" />
            </div>
            <div class="col-md-4" formGroupName="identifiers">
                <label class="col-form-label">{{ 'sample.tattoo' | translate }}</label>
                <input formControlName="tattooId" type="text" class="form-control" />
            </div>
            <div class="col-md-4" formGroupName="identifiers">
                <label class="col-form-label" for="sName">{{ 'sample.certificateId' | translate }}</label>
                <input formControlName="certificateId" type="text" class="form-control" />
            </div>
        </div>
        <!-- BIRDS-->
        <div class="form-row" formGroupName="animal" *ngIf="isBird">
            <div class="col-md-4" formGroupName="identifiers">
                <label *ngIf="isBird" class="col-form-label">{{ 'sample.birdChip' | translate }}</label>
                <input formControlName="rfid" type="text" class="form-control" [attr.focustrap]="true" />
            </div>
            <div class="col-md-4">
                <label class="col-form-label">{{ 'sample.animal.' + af.rootCategoryId.value | translate }}</label>
                <input
                    formControlName="name"
                    [placeholder]="'sample.animal.nameInfo' | translate"
                    type="text"
                    class="form-control"
                />
                <ui-show-errors [control]="af.name"></ui-show-errors>
            </div>
        </div>
        <ui-show-errors [control]="af.identifiers"></ui-show-errors>

        <div class="form-row" formGroupName="animal">
            <div class="col-md-4" *ngIf="!isBird">
                <label class="col-form-label" for="sex">{{ 'sample.sex' | translate }}</label>
                <div>
                    <ng-select name="sex" id="sex" formControlName="sex">
                        <ng-option value="NA">{{ 'sample.unknown' | translate }}</ng-option>
                        <ng-option value="M">{{ 'sample.male' | translate }}</ng-option>
                        <ng-option value="F">{{ 'sample.female' | translate }}</ng-option>
                    </ng-select>
                </div>
                <ui-show-errors [control]="af.sex"></ui-show-errors>
            </div>
            <div class="col-md-4">
                <label class="col-form-label">{{
                    (isBird ? 'sample.hatchDate' : 'sample.birthDate') | translate
                }}</label>
                <p-calendar
                    formControlName="dateOfBirth"
                    inputStyleClass="form-control"
                    [showIcon]="true"
                    name="dateOfBirth"
                    [showButtonBar]="true"
                ></p-calendar>
            </div>
            <div class="col-md-4" *ngIf="!isBird">
                <label class="col-form-label">{{ 'sample.furColor' | translate }}</label>
                <input name="furColor" type="text" formControlName="furColor" class="form-control" />
                <ui-show-errors [control]="af.furColor"></ui-show-errors>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-4 required">
                <label class="col-form-label">{{ 'sample.type' | translate }}</label>

                <ng-select
                    formControlName="extractionMethodId"
                    [items]="extractionMethodSelectItems"
                    [bindValue]="'id'"
                    [bindLabel]="'label'"
                    [clearable]="false"
                >
                </ng-select>
                <ui-show-errors [control]="f.extractionMethodId"></ui-show-errors>
            </div>
            <div class="col-md-4 required" ng-class="{ 'has-error': sampleForm.sSamplingDate.$invalid }">
                <label class="col-form-label">{{ 'sample.samplingDate' | translate }}</label>
                <p-calendar
                    formControlName="samplingDate"
                    inputStyleClass="form-control"
                    [showIcon]="true"
                    name="samplingDate"
                    [showButtonBar]="true"
                ></p-calendar>
                <ui-show-errors [control]="f.samplingDate"></ui-show-errors>
            </div>
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-3 col-form-label" title="{{ 'sample.samplingByVet.title' | translate }}">{{
                'sample.samplingByVet' | translate
            }}</label>
            <div class="col-sm-9">
                <span class="toggle-wrap pt-1">
                    <label class="c-switch c-switch-label c-switch-pill c-switch-primary">
                        <input class="c-switch-input" type="checkbox" formControlName="samplingByVet" />
                        <span class="c-switch-slider" data-checked="✓" data-unchecked="✕"></span>
                    </label>
                </span>
            </div>
        </div>
        <div *ngIf="f.samplingByVet.value === false && !adminMode" class="col-12 alert alert-info">
            {{ 'sample.samplingByVetWarning' | translate }}
        </div>

        <div class="form-row" *ngIf="f.samplingByVet.value === true">
            <div class="col-md-4">
                <label class="col-form-label" title="{{ 'sample.samplingByVet.title' | translate }}">{{
                    'sample.samplingVetNo' | translate
                }}</label>
                <input type="text" formControlName="vetNo" class="form-control" />
            </div>

            <div class="col-md-4">
                <label class="col-form-label" title="{{ 'sample.samplingByVet.title' | translate }}">{{
                    'sample.samplingVetName' | translate
                }}</label>
                <input type="text" formControlName="vetName" class="form-control" />
            </div>

            <div class="col-md-4">
                <label class="col-form-label" title="{{ 'sample.samplingByVet.title' | translate }}">
                    {{ 'sample.samplingVetAddress' | translate }}
                </label>
                <input type="text" formControlName="vetAddress" class="form-control" />
            </div>
            <div class="warning-feedback" style="display: block">
                <span class="validation-message warning font-sm">{{
                    'sample.samplingByVet.stampWarn' | translate
                }}</span
                ><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}-->
            </div>
        </div>

        <div class="row mt-2">
            <label class="col-sm-3 col-form-label" title="{{ 'sample.showDifferentOwner.title' | translate }}">{{
                'sample.showDifferentOwner' | translate
            }}</label>
            <div class="col-sm-9" title="{{ 'sample.showDifferentOwner.title' | translate }}">
                <span class="toggle-wrap pt-1">
                    <label class="c-switch c-switch-label c-switch-pill c-switch-primary">
                        <input class="c-switch-input" type="checkbox" formControlName="showDifferentOwner" />
                        <span class="c-switch-slider" data-checked="✓" data-unchecked="✕"></span>
                    </label>
                </span>
            </div>
        </div>

        <div class="form-row" *ngIf="f.showDifferentOwner.value === true">
            <div class="col-sm-12 col-md-10">
                <label class="col-form-label" title="{{ 'sample.showDifferentOwner.title' | translate }}">{{
                    'sample.ownerName' | translate
                }}</label>
                <input type="text" formControlName="ownerName" class="form-control" />
            </div>
            <!-- <div class="col-sm-7 col-md-6">
                <label class="col-form-label" title="{{ 'sample.showDifferentOwner.title' | translate }}">{{
                    'sample.ownerSurname' | translate
                }}</label>
                <input type="text" formControlName="ownerSurname" class="form-control" />
            </div> -->
            <div class="col-sm-12 col-md-6">
                <label class="col-form-label" title="{{ 'sample.showDifferentOwner.title' | translate }}">
                    {{ 'sample.ownerStreet' | translate }}
                </label>
                <input type="text" formControlName="ownerStreet" class="form-control" />
            </div>
            <div class="col-md-3">
                <label class="col-form-label" title="{{ 'sample.showDifferentOwner.title' | translate }}">{{
                    'sample.ownerCity' | translate
                }}</label>
                <input type="text" formControlName="ownerCity" class="form-control" />
            </div>
            <div class="col-md-3">
                <label class="col-form-label" title="{{ 'sample.showDifferentOwner.title' | translate }}">{{
                    'sample.ownerCountry' | translate
                }}</label>

                <ng-select
                    formControlName="ownerCountry"
                    [bindValue]="'id'"
                    [bindLabel]="'label'"
                    [clearable]="true"
                    [items]="countries"
                ></ng-select>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-4" *ngIf="adminMode" ng-class="{ 'has-error': sampleForm.note.$invalid }">
                <label class="col-form-label">{{ 'sample.note' | translate }}</label>
                <input
                    type="text"
                    ng-maxlength="100"
                    class="form-control"
                    formControlName="note"
                    placeholder="{{ 'sample.note' | translate }}"
                />
            </div>
        </div>

        <!-- Vzorka admin info-->
        <div class="form-row" *ngIf="adminMode">
            <div class="col-md-4">
                <label class="col-form-label">{{ 'sample.labCode' | translate }}</label>

                <input
                    type="text"
                    class="form-control"
                    formControlName="labCode"
                    placeholder="{{ 'sample.labCode' | translate }}"
                />
                <ui-show-errors [control]="f.labCode"></ui-show-errors>
                <ng-container *ngIf="labcodeCheckMessage() as r">
                    <div *ngIf="!r.available" class="invalid-feedback" style="display: block">
                        <span class="validation-message">{{ 'sample.duplicateLabCode' | translate }}</span>
                        <a class="pl-2" target="_blank" [routerLink]="['/samples', r.existingSampleId]">Existujúca vzorka</a>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-4">
                <label class="col-form-label">{{ 'sample.quality' | translate }}</label>
                <input
                    type="text"
                    ng-maxlength="100"
                    class="form-control"
                    formControlName="quality"
                    placeholder="{{ 'sample.quality' | translate }}"
                />
            </div>
            <div class="col-md-4">
                <label class="col-form-label">{{ 'sample.receiptDate' | translate }}</label>
                <p-calendar
                    formControlName="receivedDate"
                    inputStyleClass="form-control"
                    [showIcon]="true"
                    name="receivedDate"
                    [showButtonBar]="true"
                ></p-calendar>
            </div>
        </div>
        <hr />

        <ng-content></ng-content>

        <!-- END vzorka admin info-->
    </div>
    <div class="card-footer">
        <ui-form-errors [form]="sampleFormGroup" [showDetails]="debug"></ui-form-errors>
    </div>
</div>
