<ul class="list-none p-0 m-0 flex flex-column md:flex-row justify-items-center">
    <li class="relative mr-0 flex-1" *ngFor="let stage of stages; let i = index">
        <span
            *ngIf="i < stages.length - 1"
            class="hidden md:block absolute right-0 border-top-2 border-right-2 z-2 border-300"
            style="top: 50%; height: 20px; width: 20px; transform: translateX(9px) translateY(-50%) rotate(45deg)"
            [ngClass]="{
                'border-green-500': progress - 1 >= i,
                'bg-green-500': stage.id === currentStage,
                'surface-card': stage.id !== currentStage
            }"
        >
        </span>
        <span
            *ngIf="i < stages.length - 1"
            class="md:hidden absolute border-bottom-2 border-right-2 z-2 border-300"
            style="
                top: 100%;
                left: 50%;
                height: 20px;
                width: 20px;
                transform: translateX(-50%) translateY(-10px) rotate(45deg);
            "
            [ngClass]="{
                'border-green-500': progress - 1 >= i,
                'bg-green-500': stage.id === currentStage,
                'surface-card': stage.id !== currentStage
            }"
        >
        </span>
        <div
            class="h-full border-2 border-300 p-1 pl-2 py-3 md:py-1 flex flex-column md:flex-row align-items-center z-1"
            [ngClass]="{
                'border-green-500': progress - 1 >= i,
                'border-top-none md:border-top-1 md:border-left-none': i > 0,
                'bg-green-500': stage.id === currentStage,
                'surface-card': stage.id !== currentStage
            }"
        >
            <div class="flex-1">
                <div
                    class="w-full text-900 font-medium mb-1 flex md:flex-row justify-content-center align-items-center"
                    [ngClass]="{ 'text-white': stage.id === currentStage }"
                >
                    <div>
                        <i
                            *ngIf="i < progress - 1"
                            class="pi pi-check-circle text-green-500 text-xl mb-2 md:mb-0 mr-2"
                        ></i>
                        <span
                            *ngIf="i >= progress - 1"
                            [ngClass]="{ 'text-white border-white': stage.id === currentStage }"
                            class="text-600 border-500 border-1 border-circle flex align-items-center justify-content-center border-circle mr-2"
                            style="min-width: 1.5rem; min-height: 1.5rem"
                        >
                            {{ i + 1 }}
                        </span>
                        <!-- <i
                            *ngIf="stage.id === currentStage"
                            class="pi pi-circle text-blue-600 text-2xl md:text-xl mb-2 md:mb-0 mr-0 md:mr-2"
                        ></i>
                        <i
                            *ngIf="stage.id !== currentStage && i > progress - 1"
                            class="pi pi-circle text-600 text-2xl md:text-xl mb-2 md:mb-0 mr-0 md:mr-2"
                        ></i> -->
                    </div>

                    <div>{{ stage.title | translate }}</div>
                </div>
                <span
                    class="text-600 text-sm hidden md:block text-center"
                    [ngClass]="{ 'text-green-50': stage.id === currentStage }"
                    >{{ stage.subtitle | translate }}</span
                >
            </div>
        </div>
        <div
            *ngIf="i < stages.length - 1"
            [attr.test]="i"
            class="w-full absolute top-50 left-100 surface-300 hidden md:block"
            style="transform: translateY(-50%); height: 2px"
        ></div>
    </li>
</ul>
