<h4 class="my-2">{{ 'order.samples' | translate }}</h4>
<ng-container *ngFor="let control of samplesFormArray.controls; let i = index">
    <oc-sample-form
        [adminMode]="adminMode"
        [customerSamples]="customerSamples"
        [index]="i"
        [formGroup]="$any(control)"
        [sampleFormGroup]="$any(control)"
        (remove)="removeSample($event)"
        (duplicate)="onDuplicate($event)"
    >
        <oc-items-editor
            [adminMode]="adminMode"
            [categoryId]="control.get('animal.categoryId').value"
            [rootCategoryId]="control.get('animal.rootCategoryId').value"
            formArrayName="items"
        ></oc-items-editor>
        <ui-show-errors [control]="control"></ui-show-errors>
    </oc-sample-form>
</ng-container>
