import {
    AbstractControl,
    ControlFactoryMap,
    FormGroup,
    LocalStorageManager,
    PersistManager,
    PersistOptions,
} from '@ngneat/reactive-forms'
import { from, isObservable, Observable, of } from 'rxjs'
import { UntypedFormArray } from '@angular/forms'

import { debounceTime, switchMap, take, tap } from 'rxjs/operators'
import { OrderFormModel } from './models/order-form.model'
import { Obj } from '@ngneat/reactive-forms/lib/types'
const reDateDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/

export function isPromise(value: any): value is Promise<unknown> {
    return typeof value?.then === 'function'
}

export function wrapIntoObservable<T>(value: T | Promise<T> | Observable<T>): Observable<T> {
    if (isObservable(value) || isPromise(value)) {
        return from(value)
    }

    return of(value)
}

export function persistValue$<T>(control: FormGroup<T>, key: string, options: PersistOptions<T>): Observable<T> {
    const test = control.getRawValue()

    return control.valueChanges.pipe(
        debounceTime(options.debounceTime),
        switchMap((value) =>
            wrapIntoObservable(
                //@ts-ignore
                options.manager.setValue(key, options.persistDisabledControls ? control.getRawValue() : value)
            )
        )
    )
}

export function handleFormArrays<T>(
    control: AbstractControl<T>,
    formValue: T,
    arrControlFactory: ControlFactoryMap<T>
) {
    Object.keys(formValue).forEach((controlName) => {
        const value = formValue[controlName]
        if (Array.isArray(value) && control.get(controlName) instanceof UntypedFormArray) {
            if (!arrControlFactory || (arrControlFactory && !(controlName in arrControlFactory))) {
                throw new Error(`Please provide arrControlFactory for ${controlName}`)
            }
            const current = control.get(controlName) as UntypedFormArray
            const fc = arrControlFactory[controlName]
            clearFormArray(current)
            value.forEach((v, i) => current.insert(i, fc(v)))
        }
    })
}

export function clearFormArray(control: UntypedFormArray) {
    while (control.length !== 0) {
        control.removeAt(0)
    }
}
export class OrderPersistManager<OrderFormModel> implements PersistManager<OrderFormModel> {
    setValue(key: string, data: OrderFormModel): OrderFormModel {
        localStorage.setItem(key, JSON.stringify(data))
        return data
    }

    getValue(key: string): OrderFormModel {
        const orderData = JSON.parse(localStorage.getItem(key) || '{}', (key: any, value: any) => {
            if (typeof value == 'string' && reDateDetect.exec(value)) {
                return new Date(value)
            }
            return value
        })
        return orderData
    }
}

export class OrderFormManager<T extends Obj = any> {
    persist(
        form: FormGroup<T>,
        key: string,
        { debounceTime, manager, arrControlFactory, persistDisabledControls }: PersistOptions<T>
    ): Observable<T> {
        const persistManager = manager || new LocalStorageManager()
        return this.restore(form, key, persistManager, arrControlFactory).pipe(
            switchMap(() =>
                persistValue$(form, key, {
                    debounceTime: debounceTime || 250,
                    manager: persistManager,
                    persistDisabledControls,
                })
            )
        )
    }

    private restore(
        form: FormGroup<T>,
        key: string,
        manager: PersistManager<T>,
        arrControlFactory: ControlFactoryMap<T>
    ): Observable<T> {
        return wrapIntoObservable(manager.getValue(key)).pipe(
            take(1),
            tap((value) => {
                if (!value) return
                handleFormArrays(form, value, arrControlFactory)
                form.patchValue(value, { emitEvent: true })
            })
        )
    }
}
