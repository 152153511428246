import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'
import { OrderDetailItem } from '@resultsgate-ui/api'
import { CacheService, SelectItem } from '@resultsgate-ui/shared'

@Component({
    selector: 'order-printable',
    templateUrl: './order-printable.component.html',
    styleUrls: ['./order-printable.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPrintableComponent implements OnInit {
    @Input()
    currentOrder!: OrderDetailItem
    extractionMethodMap: { [key: string]: SelectItem<number> }
    constructor(private cache: CacheService) {
        this.extractionMethodMap = this.cache.extractionMethodMap
    }

    ngOnInit(): void {}
}
