import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'oc-bird-label',
    template: `<span
        ><ng-container *ngIf="latin"
            ><em>{{ latin }}</em> - </ng-container
        >{{ local }}</span
    >`,
})
export class BirdLabelComponent implements OnInit {
    @Input()
    bird?: string

    latin?: string
    local?: string

    ngOnInit() {
        if (!this.bird) {
            this.local = ''
        } else {
            const split = this.bird.split(' - ')
            if (split.length > 2 || split.length === 1) {
                this.local = this.bird
                return
            }
            this.latin = split[0]
            this.local = split[1]
        }
    }
}
