import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NgSelectModule } from '@ng-select/ng-select'
import { SharedModule } from '@resultsgate-ui/shared'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { ModalModule } from 'ngx-bootstrap/modal'
import { NgxPrintModule } from 'ngx-print'
import { CalendarModule } from 'primeng/calendar'
import { InputGroupModule } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { RadioButtonModule } from 'primeng/radiobutton'
import { TooltipModule } from 'primeng/tooltip'
import { OrderItemsEditorComponent } from './order-items-editor/order-items-editor.component'
import { SampleListFormComponent } from './sample-list-form/samples-list-form.component'

import { RouterModule } from '@angular/router'
import { AnimalTypeIconComponent } from './animal-type-icon/animal-type-icon.component'
import { BirdLabelComponent } from './bird-label/bird-label.component'
import { InputPrefixDirective } from './input-prefix/input-prefix.directive'
import { OrderPrintableComponent } from './order-printable/order-printable.component'
import { OrderStatusComponent } from './order-status/order-status.component'
import { OrderStepsIndicator } from './order-steps-indicator/order-steps-indicator.component'
import { SampleFormWrapComponent } from './sample-form-wrap/sample-form-wrap.component'
import { VoucherBadgeComponent } from './voucher-badge/voucher-badge.component'

@NgModule({
    declarations: [
        OrderItemsEditorComponent,
        SampleListFormComponent,
        OrderPrintableComponent,
        SampleFormWrapComponent,
        OrderStatusComponent,
        OrderStepsIndicator,
        BirdLabelComponent,
        AnimalTypeIconComponent,
        InputPrefixDirective,
        VoucherBadgeComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        CalendarModule,
        TooltipModule,
        RadioButtonModule,
        InputGroupModule,
        InputGroupAddonModule,
        CollapseModule,
        ModalModule,
        NgxPrintModule,
        NgSelectModule,
    ],
    exports: [
        OrderItemsEditorComponent,
        SampleFormWrapComponent,
        SampleListFormComponent,
        OrderPrintableComponent,
        OrderStatusComponent,
        OrderStepsIndicator,
        BirdLabelComponent,
        AnimalTypeIconComponent,
        VoucherBadgeComponent,
    ],
})
export class OrderComponentsModule {}
