import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ControlContainer } from '@angular/forms'
import { FormArray, FormControl, FormGroup, AbstractControl } from '@ngneat/reactive-forms'
import { SelectItem } from '@resultsgate-ui/shared'
import { BehaviorSubject, Observable } from 'rxjs'
import { SampleFormModel } from '../models/sample-form.model'

@Component({
    selector: 'oc-sample-list-form',
    templateUrl: './samples-list-form.component.html',
    styles: [],
})
export class SampleListFormComponent implements OnInit {
    @Input()
    internalOrder = false

    @Input()
    adminMode = false

    @Input()
    customerSamples: BehaviorSubject<SelectItem<number>[]>

    samplesFormArray: FormArray<SampleFormModel>

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit() {
        this.samplesFormArray = this.controlContainer.control as FormArray<SampleFormModel>
    }

    removeSample(indexToDelete: number) {
        this.samplesFormArray.removeAt(indexToDelete)
    }

    onDuplicate(indexToDuplicate: number) {
        this.samplesFormArray.push(this.cloneAbstractControl(this.samplesFormArray.at(indexToDuplicate)))
        const clone = this.samplesFormArray.at(this.samplesFormArray.length - 1) as FormGroup<SampleFormModel>
        clone.patchValue({ animal: { name: clone.getRawValue().animal.name + ' ' + 'copy' } })
    }

    /**
     * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
     * @param control AbstractControl
     * @returns AbstractControl
     */
    cloneAbstractControl<T extends AbstractControl>(control: T): T {
        let newControl: T

        if (control instanceof FormGroup) {
            const formGroup = new FormGroup({}, control.validator, control.asyncValidator)
            const controls = control.controls

            Object.keys(controls).forEach((key) => {
                formGroup.addControl(key, this.cloneAbstractControl(controls[key]))
            })

            newControl = formGroup as any
        } else if (control instanceof FormArray) {
            const formArray = new FormArray([], control.validator, control.asyncValidator)

            control.controls.forEach((formControl) => formArray.push(this.cloneAbstractControl(formControl)))

            newControl = formArray as any
        } else if (control instanceof FormControl) {
            newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any
        } else {
            throw new Error('Error: unexpected control value')
        }

        if (control.disabled) newControl.disable({ emitEvent: false })

        return newControl
    }
}
