import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

interface Stage {
    title: string
    subtitle: string
    id: string
    done?: boolean
    icon: string
}

@Component({
    selector: 'oc-order-steps-indicator',
    templateUrl: 'order-steps-indicator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStepsIndicator {
    @Input()
    currentStage?: string

    @Input()
    progress = 1

    stages: Stage[] = [
        {
            id: 'SAMPLES',
            title: 'order.samples',
            subtitle: 'order.samplesInfo',
            icon: '',
        },
        { id: 'COMPLETION', title: 'order.paymentAndDelivery', subtitle: 'order.paymentAndDeliveryInfo', icon: '' },
        { id: 'SUMMARY', title: 'order.summary', subtitle: 'order.summary.info', icon: '' },
    ]
}
