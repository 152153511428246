import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
    ViewChild,
} from '@angular/core'
import { FormArray, FormGroup } from '@ngneat/reactive-forms'
import { TranslateService } from '@ngx-translate/core'
import { LabCodeCheckResult, SampleDetailItem, SampleListItem, SamplesAPIService } from '@resultsgate-ui/api'
import {
    AnimalClass,
    CacheService,
    CountrySelectItems,
    CountrySelectItemsSK,
    Environment,
    SelectItem,
} from '@resultsgate-ui/shared'
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs'
import { AnimalFormModel } from '../models/animal-form.model'
import { SampleFormModel } from '../models/sample-form.model'

const disabledFieldsInExistingMode = ['animal', 'samplingByVet', 'extractionMethodId', 'samplingDate']
const disabledAdminFieldsInExistingMode = ['labCode', 'quality', 'receivedDate']
const colors = ['info', 'danger', 'success', 'primary']

@Component({
    selector: 'oc-sample-form',
    templateUrl: './sample-form-wrap.component.html',
    styleUrls: ['./sample-form-wrap.component.scss'],
})
export class SampleFormWrapComponent implements OnInit, AfterViewInit {
    colors = colors

    @Input()
    index!: number

    @Input()
    adminMode = false

    @Input()
    internalOrder = false

    @Input()
    sampleFormGroup!: FormGroup<SampleFormModel>

    @Input()
    singleMode = false

    @Input()
    customerSamples!: BehaviorSubject<SelectItem<number>[]>

    @Output()
    remove = new EventEmitter<number>()
    @Output()
    duplicate = new EventEmitter<number>()

    collapsed = false

    @ViewChild('shadowToggle') shadowToggler!: ElementRef
    @ViewChild('cardHeader') header!: ElementRef

    rootCategoriesSelectItems!: SelectItem<number>[]
    extractionMethodSelectItems!: SelectItem<number>[]
    origState: any
    isBird = false
    debug: boolean

    datePrefix: string

    unknownCategory = 3014

    countries = CountrySelectItems
    labcodeCheckMessage = signal<LabCodeCheckResult | undefined>(undefined)

    constructor(
        public cache: CacheService,
        private sampleApi: SamplesAPIService,
        private env: Environment,
        private translate: TranslateService
    ) {
        this.debug = !this.env.production
        const d = new Date()
        this.datePrefix = `${d.getFullYear() - 2000}${(d.getMonth() + 1).toString().padStart(2, '0')}${d
            .getDate()
            .toString()
            .padStart(2, '0')}/`
    }

    get f() {
        return this.sampleFormGroup.controls
    }
    get af() {
        return (this.sampleFormGroup.controls.animal as FormGroup<AnimalFormModel>).controls
    }

    ngOnInit() {
        this.countries = this.translate.currentLang === 'sk' ? CountrySelectItemsSK : CountrySelectItems
        this.origState = this.sampleFormGroup.getRawValue()

        this.rootCategoriesSelectItems = this.cache.rootCategoriesSelectItems
        this.extractionMethodSelectItems = this.cache.extractionMethodSelectItems
        this.sampleFormGroup.controls.labCode?.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => this.labcodeCheckMessage.set(undefined)),
                distinctUntilChanged(),
                filter((labCode) => (labCode || '').length > 5),
                filter((labcode) => labcode !== this.origState.labCode),
                switchMap((labCode) => {
                    return this.sampleApi.validateLabCode({ labCode })
                })
            )
            .subscribe({
                next: (result) => {
                    this.labcodeCheckMessage.set(result)
                },
                error: (err) => {
                    console.log(`Labcode check err: ${err}`)
                    this.labcodeCheckMessage.set(undefined)
                },
            })
        this.onRootCategorySelected(this.sampleFormGroup.get('animal.rootCategoryId').value, true)
        //this is embarrassing, but prevents changedAFterCheck
        setTimeout(() => {
            disabledFieldsInExistingMode.forEach((c) => {
                this.sampleFormGroup.value.mode === 'EXISTING'
                    ? this.sampleFormGroup.get(c).disable()
                    : this.sampleFormGroup.get(c).enable()
            })
            if (this.adminMode) {
                disabledAdminFieldsInExistingMode.forEach((c) => {
                    this.sampleFormGroup.value.mode === 'EXISTING'
                        ? this.sampleFormGroup.get(c).disable()
                        : this.sampleFormGroup.get(c).enable()
                })
            }
        }, 100)
    }

    ngAfterViewInit(): void {
        this.createHeaderListener()
    }

    createHeaderListener() {
        const target = this.header
        const observer = new IntersectionObserver(
            function (entries) {
                if (entries.length > 1) {
                    return
                }
                // no intersection with screen
                if (entries[0].intersectionRatio === 0) target.nativeElement.classList.add('shadow')
                // fully intersects with screen
                else if (entries[0].intersectionRatio === 1) target.nativeElement.classList.remove('shadow')
            },
            { threshold: [0, 1], rootMargin: '-104px' }
        )
        observer.observe(this.shadowToggler.nativeElement)
    }

    onRootCategorySelected(selectedId: number | undefined, initial = false) {
        if (!initial) {
            this.sampleFormGroup.get('animal.categoryId').reset()
            this.sampleFormGroup.get('animal.categoryId').markAsDirty()
            // we need to trigger validator update on furcolor, because it depends on category
            this.sampleFormGroup.get('animal.furColor').updateValueAndValidity()
            this.sampleFormGroup.get('animal.sex').updateValueAndValidity()
            this.sampleFormGroup.get('animal.name').updateValueAndValidity()
            this.sampleFormGroup.get('extractionMethodId').reset()
        }

        this.isBird = selectedId === AnimalClass.BIRD
        this.extractionMethodSelectItems = this.cache.extractionMethodSelectItems.filter((si) =>
            si.data?.includes('' + selectedId)
        )
        if (!this.sampleFormGroup.get('labCode').value && this.adminMode) {
            this.sampleFormGroup.get('labCode').setValue(this.datePrefix)
        }
        //  this.sampleFormGroup.get('extractionMethodId').setValue(this.env.defaultBirdSamplingMethod)
    }

    existingSampleSelected(sample: SampleListItem) {
        if (sample) {
            this.sampleApi.getSampleDetails({ id: sample.id }).subscribe((data) => {
                this.onRootCategorySelected(data.animal?.category?.parentId)
                this.sampleFormGroup.patchValue(this.modelToForm(data))
                ;(this.sampleFormGroup.get('items') as FormArray).clear()
            })
        }
    }

    toggleMode() {
        ;(this.sampleFormGroup.get('items') as FormArray).clear()
        const newMode = this.sampleFormGroup.get('mode').value
        if (newMode === 'NEW' && this.origState.mode === 'NEW') {
            this.sampleFormGroup.reset(this.origState)
            // this.sampleFormGroup.patchValue({ mode: 'NEW' })
        }
        disabledFieldsInExistingMode.forEach((c) => {
            newMode === 'EXISTING' ? this.sampleFormGroup.get(c).disable() : this.sampleFormGroup.get(c).enable()
        })
        if (this.adminMode) {
            disabledAdminFieldsInExistingMode.forEach((c) => {
                newMode === 'EXISTING' ? this.sampleFormGroup.get(c).disable() : this.sampleFormGroup.get(c).enable()
            })
        }
    }

    sampleSearchFn(term: string, item: SampleListItem) {
        // TODO improve perf
        term = term
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        return (
            item.animalName
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .indexOf(term) > -1 || item.breed.toLowerCase().indexOf(term) > -1
        )
    }

    removeSample(index: number) {
        this.remove.emit(index)
    }

    onDuplicate(index: number) {
        this.duplicate.emit(index)
    }

    modelToForm(sample: SampleDetailItem): SampleFormModel {
        const animal: AnimalFormModel = {
            ...sample.animal,
            dateOfBirth: sample.animal.dateOfBirth,
            categoryId: sample.animal.category.id,
            rootCategoryId: sample.animal.category.parentId,
            identifiers: {
                rfid: sample.animal.rfid,
                tattooId: sample.animal.tattooId,
                certificateId: sample.animal.certificateId,
            },
            categoryInfo: '',
        }
        return {
            ...sample,
            mode: 'EXISTING',
            existingSampleId: sample.id,
            extractionMethodId: sample.extractionMethod!.id,
            receivedDate: sample.receivedDate,
            samplingDate: sample.samplingDate,
            animal,
        }
    }
}
