import { Validators } from '@angular/forms'
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms'
import { ArrayValidators, identityValidator } from '@resultsgate-ui/shared'
import { AnimalFormModel, AnimalIdentifiersFormModel } from './models/animal-form.model'
import { ItemFormModel, SampleFormModel } from './models/sample-form.model'
import { RequiredForDogsValidator } from './required-for-dogs.validator'

export class OrderFormBuilder {
    constructor(private fb: FormBuilder) {}
    createSampleForm(adminMode: boolean, sampleData?: SampleFormModel): FormGroup<SampleFormModel> {
        const mode = this.fb.control('NEW')
        const animal = this.fb.group<AnimalFormModel>({
            age: [null],
            identifiers: this.fb.group<AnimalIdentifiersFormModel>(
                {
                    certificateId: [null],
                    rfid: [null],
                    tattooId: [null],
                },
                { validators: identityValidator }
            ),
            rootCategoryId: [1],
            categoryId: [null, Validators.required],
            categoryInfo: [null],
            dateOfBirth: [null],
            furColor: [null, RequiredForDogsValidator],
            location: [null],
            name: [null, RequiredForDogsValidator],
            sex: [null, RequiredForDogsValidator],
        })

        const itemsArray = this.fb.array<ItemFormModel>([], ArrayValidators.minLength(1))

        const sampleFormGroup = this.fb.group<SampleFormModel>({
            mode: ['NEW'],
            animal,
            existingSampleId: [undefined],
            extractionMethodId: [undefined, Validators.required],
            labCode: [undefined],
            quality: [undefined],
            receivedDate: [adminMode ? new Date().toISOString() : undefined],
            note: [undefined],
            ownerCity: [undefined],
            ownerCountry: [undefined],
            ownerName: [undefined],
            // ownerSurname: [null],
            ownerStreet: [undefined],
            samplingByVet: [false],
            samplingDate: [undefined, Validators.required],
            showDifferentOwner: [false],
            sampleUUID: this.uuidv4(),
            vetAddress: [undefined],
            vetName: [undefined],
            vetNo: [undefined],
            items: itemsArray,
        })
        if (sampleData) {
            sampleData.items.forEach((i) => {
                const itemGroup = this.fb.group({
                    analysisId: [null, Validators.required],
                    analysisDate: [adminMode ? new Date() : null],
                    analysisGroupApplied: [null],
                    existingOrderItemId: [null],
                    includingDecorativeCert: [false],
                    priorityDelivery: [false],
                    note: [null],
                    resultId: [null],
                })
                itemGroup.patchValue(i)
                itemsArray.push(itemGroup)
            })

            sampleFormGroup.patchValue(sampleData)
        }
        return sampleFormGroup
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }
}
